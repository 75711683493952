.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.highlight {
  color: #004fa3;
}

.light {
  color: white;
}

a:link {
  color: #004fa3;
}

a:visited {
  color: #460f85;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background-color: gainsboro;
  width: 100vw;
}

form {
  background-color: gainsboro;
  padding: 2%;
  align-content: left;
  align-items: left;
  text-align: left;

  border-radius: 0.5em;
}

form button {
  margin-top: 1em;
  margin-left: 50%;
  transform: translate(-50%, 0);
  width: 50%;
}

#content {
  padding: 150px 3% 8% 3%;
  top: 0;
  background-color: white;
  border-left: 1px solid black;
  border-right: 1px solid black;
  min-height: 100vh;
  height: fit-content;
}

#upload-modal input {
  transform: translate(0, 25%);
}

section {
  margin: 1%;
  margin-left: 50%;
  transform: translate(-50%, 0);
  padding: 1%;
  background-color: white;
  box-shadow: 0 0 5em 5em gainsboro inset;
  border-radius: 0.5em;
  width: 100%;
  height: fit-content;
}

.success-toast {
  margin-right: 8%;
  margin-top: 19%;
  width: 300px;
  height: 80px;
  background-color: white;
}

.toast-body {
  font-size: 1rem;
  text-align: left;
  word-wrap: break-word;
  width: 100%;
  color: black;
}

.toast-progress {
  height: 3px;
  background: blue;
}

#alerts {
  position: fixed;
  top: 3rem;
  width: 100vw;
  height: fit-content;
  padding: 2%;
  z-index: 800;
}

.dropdown-menu {
  max-height: 400%;
  overflow-y: scroll;
}

.dropzone div#outer {
  min-height: 15rem;
  height: auto;
  border-width: 0.2rem;
  border-color: #004fa3;
  border-style: dashed;
  width: inherit;
  border-radius: 2rem;
  align-content: center;
  justify-content: center;
  margin: 2%, 0, 2%, 0;
}

.dropzone div#outer:hover {
  border-color: #1289d8;
  box-shadow: 0 0 1rem 0 #004fa381, 0 0 1rem 0 #004fa381 inset;
}

.dropzone .fileList {
  margin: 2%;
  padding: 0.5%;
  border-radius: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  height: 12.5rem;
  width: initial;
  background-color: white;
}

.envIndicatorHeaderOuter {
  text-align: center;
  justify-content: center;
  list-style: none;
  width: 100%;
}

.envIndicatorHeaderSmall {
  font-size: small;
}

.envIndicatorHeaderInnerLocal {
  background-color: #f7f977;
  color: red;
  height: 48px;
  width: 100vw;
  font-size: large;
  padding-bottom: 4px;
}

.envIndicatorFooterLocal {
  background-color: #f7f977;
  color: red;
  width: 100%;
  text-align: center;
  justify-content: center;
  width: '100%';
  font-size: '0.75rem';
  font-weight: bold;
}

.envIndicatorHeaderInnerDev {
  background-color: #f7f977;
  color: red;
  height: 48px;
  width: 100vw;
  font-size: large;
  padding-bottom: 4px;
}

.envIndicatorFooterDev {
  background-color: #f7f977;
  color: red;
  width: 100%;
  text-align: center;
  justify-content: center;
  width: '100%';
  font-size: '0.75rem';
  font-weight: bold;
}

.envIndicatorHeaderInnerSandBox {
  background-color: #f7f977;
  color: red;
  height: 48px;
  width: 100vw;
  font-size: large;
  padding-bottom: 4px;
}

.envIndicatorFooterSandBox {
  background-color: #f7f977;
  color: red;
  width: 100%;
  text-align: center;
  justify-content: center;
  width: '100%';
  font-size: '0.75rem';
  font-weight: bold;
}

.envIndicatorHeaderInnerNonProd {
  background-color: #f7f977;
  color: red;
  height: 48px;
  width: 100vw;
  font-size: large;
  padding-bottom: 4px;
}

.envIndicatorFooterNonProd {
  background-color: #f7f977;
  color: red;
  width: 100%;
  text-align: center;
  justify-content: center;
  width: '100%';
  font-size: '0.75rem';

  font-weight: bold;
}

@media screen and (max-width: 425px) {
  .envIndicatorHeaderInnerLocal,
  .envIndicatorHeaderInnerDev,
  .envIndicatorHeaderInnerNonProd,
  .envIndicatorHeaderInnerSandBox,
  .envIndicatorHeaderSmall,
  .envIndicatorFooterSandBox,
  .envIndicatorFooterNonProd,
  .envIndicatorFooterLocal,
  .envIndicatorFooterLocal {
    font-size: 4vw;
  }
}

@media screen and (max-width: 767px) {
  /* .envIndicatorHeaderOuter {
    position: relative;
    margin: auto;
    margin-left: 50%;
    transform: translate(-50%, 0);
    border-radius: 0.5em;
    padding: 0;
    margin-bottom: 5%;
    width: inherit;
  } */

  #content {
    padding-bottom: 10rem;
  }
}

#title h1 {
  font-size: 5vw;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  #title h1 {
    font-size: 2rem;
  }
}

@media screen and (min-width: 990px) {
  #title h1 {
    font-size: 3rem;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}