.floatingWindow {
  position: fixed;
  height: 77vh;
  width: 90vw;
  z-index: 900 !important;
  background-color: white;
  border-radius: 1em;
  box-shadow: 0px 0px 100rem 100rem rgba(0, 0, 0, 0.4);
  z-index: 1;
  overflow-y: auto;
  overflow-x: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  padding: 0 2%;
  transition: width 0.3s, height 0.3s;
}

.floatingWindow .btn {
  margin: 10px;
}
